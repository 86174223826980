import React, { FC } from 'react'
import './Breadcrumb.css'

type BreadcrumbItemProps = {
  label: string
  icon: FC
  onClick?: () => any
}

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ label, icon: Icon, onClick }) => {
  return (
    <span onClick={onClick} className={onClick && 'clickable'}>
      <Icon />
      <span className='label'>{label}</span>
    </span>
  )
}

export default BreadcrumbItem