import { Button, Tooltip } from 'antd'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Id } from '../../../types/common'
import { stopInfra, useStopInfraSelector } from '../slices/stop'

type Props = {
  id: Id
}

const InfraStopButton: FC<Props> = ({ id }) => {
  const dispatch = useDispatch()

  const { loading } = useStopInfraSelector(id)

  const handleClick = () => {
    dispatch(stopInfra(id))
  }

  return (
    <Tooltip
      title={`Stopper`}
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
        danger
        style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
        size='small'
        type='primary'
        loading={loading}
        icon={<i className="ri-stop-fill" />}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default InfraStopButton