import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { ApiError } from 'redux-helper/lib/types'
import { RootState } from '../../redux/rootReducer'

export type WatcherState = {
  error: ApiError | null
}

const initialState: WatcherState = {
  error: null
}

const {
  reducer: watcherReducer,
  actions
} = createSlice({
  name: 'watcher',
  initialState,
  reducers: {
    watchError(state: WatcherState, action: PayloadAction<ApiError>) {
      state.error = action.payload
    },
    consumeError(state: WatcherState) {
      state.error = null
    }
  }
})

export default watcherReducer

export const { watchError, consumeError } = actions

export const useWatcherSelector = () =>
  useSelector((state: RootState) => state.watcher)