import { useSelector } from 'react-redux'
import { makeAddSlice } from 'redux-helper'
import { RootState } from '../../../redux/rootReducer'
import { AppThunk } from '../../../redux/store'
import { watchError } from '../../watcher/watcher'
import CustomersApi from '../api'
import { NewCustomer } from '../types'
import { getCustomers } from './get'

const {
  reducer: addCustomerReducer,
  actions: { start, success, error, reset },
} = makeAddSlice('addCustomer')

export default addCustomerReducer

export const addCustomer = (customer: NewCustomer): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      await CustomersApi.createCustomer(customer)
      dispatch(success())
      dispatch(getCustomers())
    } catch (err) {
      dispatch(watchError(err))
      dispatch(error(err))
    }
    dispatch(reset())
  }

export const useAddCustomerSelector = () =>
  useSelector((state: RootState) => state.customers.add)