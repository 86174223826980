import { Alert } from 'antd'
import React, { FC } from 'react'
import { ApiError } from 'redux-helper/lib/types'
import LoadingSpin from './LoadingSpin'

type AsyncContentProps = {
  data: any
  init?: boolean
  loading: boolean
  error: ApiError | null
  errorDescription: string
}

const AsyncContent: FC<AsyncContentProps> = ({
  data, init, loading, error, errorDescription, children
}) => {
  if (error !== null) {
    return <Error description={errorDescription} />
  }  else if (init !== undefined && init === false) {
    return <Loading />
  } else if (data !== undefined) {
    return <>{children}</>
  } else if (loading) {
    return <Loading />
  } else {
    return null
  }
}

type ErrorProps = {
  description: string
}

const Error: FC<ErrorProps> = ({ description }) => (
  <Alert
    message="Erreur"
    description={description}
    type="error"
  />
)

const Loading: FC = () => <LoadingSpin />

export default AsyncContent