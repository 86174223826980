import { useSelector } from 'react-redux';
import { makeGetSlice } from 'redux-helper';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { watchError } from '../../watcher/watcher';
import EventsApi from '../api';
import { Event } from '../types';

const {
  reducer: getEventsReducer,
  actions: { start, success, error, reset },
} = makeGetSlice<Event>('getEvents')

export type GetEventsState = ReturnType<typeof getEventsReducer>

export default getEventsReducer

export const getEvents = (): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      const events: Event[] = await EventsApi.getEvents()
      dispatch(success(events))
    } catch (err) {
      dispatch(watchError(err))
      dispatch(error(err))
    }
    dispatch(reset())
  }

export const useGetEventsSelector = () =>
  useSelector((state: RootState) => state.events.get)