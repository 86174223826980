import React, { FC } from 'react';
import Breadcrumb from '../../../../app/Breadcrumb';
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem';
import useRouting from '../../../../hooks/useRouting';
import customersIcons from './icons';
import customersLabels from './labels';

const CustomersBreadcrumb: FC = ({ children }) => {
  const { goToCustomers } = useRouting()

  return (
    <Breadcrumb>
      <BreadcrumbItem
        label={customersLabels.name.plural}
        icon={customersIcons.default}
        onClick={goToCustomers}
      />
      {children}
    </Breadcrumb>
  )
}

export default CustomersBreadcrumb