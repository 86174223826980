import { Result, Button } from 'antd'
import React, { FC } from 'react'
import useRouting from '../hooks/useRouting'

const ForbiddenPage: FC = () => {
  const { goToHome } = useRouting()

  return (
    <Result
        status='403'
        title='403'
        subTitle={`Désolé, vous n'êtes pas autorisé à accéder à cette page`}
        extra={
          <Button
            type='primary'
            onClick={goToHome}
          >
            Revenir à l'acceuil
          </Button>
        }
      />
  )
}

export default ForbiddenPage