import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Page from '../../../../app/Page'
import AsyncContent from '../../../../common/AsyncContent'
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem'
import UsersSection from '../../../customers-users/components/table/Section'
import { getCustomers, useGetCustomersSelector } from '../../slices/get'
import CustomersBreadcrumb from '../common/Breadcrumb'
import customersIcons from '../common/icons'
import customersLabels from '../common/labels'
import CustomerDetailsForm from './Form'

const CustomerDetailsPage: FC = () => {
  const dispatch = useDispatch()
  const { customerId } = useParams() as { customerId: string }

  const { data: { customer }, ...state } = useCustomerDetailsPageSelector(customerId)

  useEffect(() => {
    dispatch(getCustomers())
  }, [dispatch])

  return (
    <Page
      title={customersLabels.details.button}
      breadcrumb={Breadcrumb}
    >
      <AsyncContent
        {...state}
        data={customer}
        errorDescription={`Echec du chargement du client`}
      >
        <CustomerDetailsForm initialValues={customer} />
        <UsersSection secondary />
      </AsyncContent>
    </Page>
  )
}

const Breadcrumb = () => (
  <CustomersBreadcrumb>
    <BreadcrumbItem
      label={customersLabels.details.button}
      icon={customersIcons.details}
    />
  </CustomersBreadcrumb>
)

export default CustomerDetailsPage

const useCustomerDetailsPageSelector = (id: string) => {
  const customers = useGetCustomersSelector()
  const customer = customers.data.byId[id]
  return {
    data: {
      customer,
    },
    loading: customers.loading,
    error: customers.error,
  }
}