import { Button, Popconfirm } from 'antd'
import { BaseButtonProps } from 'antd/lib/button/button'
import React, { FC } from 'react'

type DeleteButtonProps = BaseButtonProps & Omit<React.ButtonHTMLAttributes<any>, 'type' | 'onClick'> & {
  onClick: () => void
}

const DeleteButton: FC<DeleteButtonProps> = ({ onClick, children, ...props }) => {
  return (
    <Popconfirm
      title='Confirmer la suppression ?'
      placement='left'
      onConfirm={onClick}
      okText='Oui'
      cancelText='Non'
    >
      <Button {...props}>
        {children}
      </Button>
    </Popconfirm>
  )
}

export default DeleteButton