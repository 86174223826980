import { Button, Form, Input, message } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import useRouting from '../../../../hooks/useRouting'
import formRules from '../../../../utils/rules'
import { addUser, useAddUserSelector } from '../../slices/add'
import customersUsersLabels from '../common/labels'

const AddUserForm: FC = () => {
  const dispatch = useDispatch()
  const { customerId } = useParams() as { customerId: string }
  const { goBack } = useRouting()
  const [firstname, setFirstname] = useState<string>()
  const [lastname, setLastname] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [errorMessage, setErrorMessage] = useState('')

  const { success, error } = useAddUserSelector()

  const handleFinish = () => {
    if (firstname && lastname && password) {
      dispatch(addUser(customerId, {
        firstname, lastname, password,
      }))
    }
  }

  useEffect(() => {
    if (success) {
      goBack()
      message.success(customersUsersLabels.add.success)
    } else if (error) {
      const errorMessageMap: { [code: number]: string } = {
        400: 'Adresse email déjà utilisée',
      }
      const message = errorMessageMap[error.statusCode] || `Erreur interne, merci de réessayer plus tard`
      setErrorMessage(message)
    }
  }, [success, error, goBack, dispatch])

  return (
    <Form
      layout='vertical'
      onFinish={handleFinish}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 11 }}
    >
      <Form.Item
        label='Prénom'
        name='firstname'
        validateTrigger='onBlur'
        rules={[formRules.required]}
      >
        <Input value={firstname} onChange={e => setFirstname(e.target.value)} />
      </Form.Item>
      <Form.Item
        label='Nom'
        name='lastname'
        validateTrigger='onBlur'
        rules={[formRules.required]}
      >
        <Input value={lastname} onChange={e => setLastname(e.target.value)} />
      </Form.Item>
      <Form.Item
        label='Mot de passe'
        name='password'
        validateTrigger='onBlur'
        rules={[formRules.required]}
      >
        <Input.Password
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={errorMessage ? 'error' : 'validating'}
        help={errorMessage}
      >
        <Button type='primary' htmlType='submit'>
          Créer
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AddUserForm