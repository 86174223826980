import axios from 'axios'
import config from '../../config/config'
import { Id } from '../../types/common'
import { errorWrapper } from '../../utils/api'
import { Infra } from '../infras'
import { InfraChanges, NewInfra } from './types'

const url = `${config.serverBaseUrl}/manager/infras`

class InfrasApi {
  static getInfras = () => errorWrapper(
    async (): Promise<Infra[]> => {
      const { data } = await axios.get(url)
      return data
    }
  )

  static createInfra = (infra: NewInfra) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(url, infra)
      return data
    }
  )

  static updateInfra = ({ id, ...changes }: InfraChanges) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.put(`${url}/${id}`, changes)
      return data
    }
  )

  static startInfra = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/${id}/start`)
      return data
    }
  )

  static resumeInfra = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/${id}/resume`)
      return data
    }
  )

  static syncInfra = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/${id}/sync`)
      return data
    }
  )

  static suspendInfra = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/${id}/suspend`)
      return data
    }
  )

  static stopInfra = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/${id}/stop`)
      return data
    }
  )

  static lockInfra = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/${id}/lock`)
      return data
    }
  )

  static unlockInfra = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/${id}/unlock`)
      return data
    }
  )

  static backupInfra = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.get(`${url}/${id}/backup`)
      return data
    }
  )

  static deleteInfra = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.delete(`${url}/${id}`)
      return data
    }
  )
}

export default InfrasApi