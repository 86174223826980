import { combineReducers } from 'redux';
import addInfraReducer from './slices/add';
import updateInfraReducer from './slices/update';
import startInfraReducer from './slices/start';
import stopInfraReducer from './slices/stop';
import suspendInfraReducer from './slices/suspend';
import syncInfraReducer from './slices/sync';
import resumeInfraReducer from './slices/resume';
import lockInfraReducer from './slices/lock';
import unlockInfraReducer from './slices/unlock';
import backupInfraReducer from './slices/backup';
import deleteInfraReducer from './slices/delete';
import getInfrasReducer from './slices/get';

const infrasReducer = combineReducers({
  get: getInfrasReducer,
  add: addInfraReducer,
  update: updateInfraReducer,
  start: startInfraReducer,
  resume: resumeInfraReducer,
  sync: syncInfraReducer,
  suspend: suspendInfraReducer,
  stop: stopInfraReducer,
  lock: lockInfraReducer,
  unlock: unlockInfraReducer,
  backup: backupInfraReducer,
  delete: deleteInfraReducer,
})

export default infrasReducer