import React, { FC } from 'react'

const Icon: FC = () => (
  <div style={{ height: 72, padding: '16px 24px' }}>
    <img
      style={{ height: 32 }}
      alt='Sologic'
      src='sologic-icon.png'
    />
  </div>
)

export default Icon