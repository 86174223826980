import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ApiError } from '../../../types/api';
import { Id } from '../../../types/common';
import { RemoteStateWithSuccess } from '../../../types/redux';
import { watchError } from '../../watcher/watcher';
import InfraApi from '../api';
import { getInfras } from './get';

export type LockInfraState = { [id: string]: RemoteStateWithSuccess }

const initialState: LockInfraState = {}

const {
  reducer,
  actions,
} = createSlice({
  name: 'lockInfra',
  initialState,
  reducers: {
    start: (state: LockInfraState, action: PayloadAction<Id>) => {
      const id = action.payload
      const newState = {
        success: false,
        loading: true,
        error: null,
      }
      state[id] = newState
    },
    success: (state: LockInfraState, action: PayloadAction<Id>) => {
      const id = action.payload
      const newState = {
        success: true,
        loading: false,
        error: null,
      }
      state[id] = newState
    },
    error: (state: LockInfraState, action: PayloadAction<{ id: Id, error: ApiError }>) => {
      const { id, error } = action.payload
      const newState = {
        success: false,
        loading: false,
        error: error,
      }
      state[id] = newState
    },
    reset: (state: LockInfraState, action: PayloadAction<Id>) => {
      const id = action.payload
      const newState = {
        success: false,
        loading: false,
        error: null,
      }
      state[id] = newState
    },
  }
})

export default reducer

export const lockInfra = (id: Id): AppThunk =>
  async dispatch => {
    try {
      dispatch(actions.start(id))
      await InfraApi.lockInfra(id)
      dispatch(actions.success(id))
      dispatch(getInfras())
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(actions.error({ id, error: err as ApiError }))
    }
    dispatch(actions.reset(id))
  }

export const useLockInfraSelector = (id: Id): RemoteStateWithSuccess =>
  useSelector((state: RootState) => {
    const lockState: RemoteStateWithSuccess | undefined = state.infras.lock[id]
    return lockState || initialState
  })