import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { FC } from 'react'
import useRouting from '../../../../hooks/useRouting'
import { Customer } from '../../types'
import customersLabels from '../common/labels'

export type CustomersTableRow = Customer & {
  key: number
}

type CustomerTableProps = {
  rows: CustomersTableRow[]
}

const CustomerTable: FC<CustomerTableProps> = ({ rows }) => {
  const { goToCustomerDetails } = useRouting()

  const columns: ColumnsType<CustomersTableRow> = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: CustomersTableRow, b: CustomersTableRow) => a.name < b.name ? -1 : 1,
      showSorterTooltip: false,
      render: (name: string) => name
    },
  ]

  const onRow = ({ id }: CustomersTableRow) => ({
    onClick: () => goToCustomerDetails(id)
  })

  return (
    <>
      <Table
        size='small'
        columns={columns}
        dataSource={rows}
        onRow={onRow}
        pagination={{ defaultPageSize: 15 }}
        locale={{
          filterReset: 'Réinitialiser',
          emptyText: customersLabels.table.empty
        }}
      />
    </>
  )
}

export default CustomerTable