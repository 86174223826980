import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, message, Space, Tooltip } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useRouting from '../../../../hooks/useRouting'
import { Id } from '../../../../types/common'
import formRules from '../../../../utils/rules'
import CustomerMultiSelect from '../../../customers/components/select/MultiSelect'
import CustomerSelect from '../../../customers/components/select/Select'
import { addInfra, useAddInfraSelector } from '../../slices/add'
import { InfraSize } from '../../types'
import dico from '../common/labels'
import InfraSizeSelect from '../select/SizeSelect'

const AddInfraForm: FC = () => {
  const dispatch = useDispatch()
  const { goBack } = useRouting()
  const [machine, setMachine] = useState<string>()
  const [size, setSize] = useState<InfraSize>()
  const [customers, setCustomers] = useState<Id[]>([])
  const [customer, setCustomer] = useState<Id>()
  const [dedicated, setDedicated] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { success, error } = useAddInfraSelector()

  const handleFinish = () => {
    if (machine && size && customers) {
      dispatch(addInfra({
        machine, size, dedicated,
        customers: dedicated ? [customer as Id] : customers
      }))
    }
  }

  useEffect(() => {
    if (success) {
      goBack()
      message.success(dico.add.success)
    } else if (error) {
      const errorMessageMap: { [code: number]: string } = {
        400: 'Nom de machine déjà utilisé',
      }
      const message = errorMessageMap[error.statusCode] || `Erreur interne, merci de réessayer plus tard`
      setErrorMessage(message)
    }
  }, [success, error, goBack, dispatch])

  return (
    <Form
      layout='vertical'
      onFinish={handleFinish}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 11 }}
    >
      <Form.Item
        label={(
          <Space align='center' size='small'>
            <span>Nom</span>
            <Tooltip
              placement='right'
              title='Le nom du serveur détermine le nom de domaine à partir duquel il est accessible'
              mouseEnterDelay={1}
              mouseLeaveDelay={0}
            >
              <QuestionCircleOutlined style={{ color: '#999' }} />
            </Tooltip>
          </Space>
        )}
        name='machine'
        rules={[formRules.required, formRules.machine]}
      >
        <Input value={machine} onChange={e => setMachine(e.target.value)} />
      </Form.Item>
      <Form.Item label='Taille' name='size' rules={[formRules.required]}>
        <InfraSizeSelect value={size} onChange={value => setSize(value)} />
      </Form.Item>
      <Form.Item name='dedicated'>
        <Checkbox checked={dedicated} onChange={e => { setDedicated(e.target.checked) }}>
          Dédié
        </Checkbox>
      </Form.Item>
      {dedicated
        ? (
          <Form.Item label='Client' name='customer'>
            <CustomerSelect value={customer} onChange={value => setCustomer(value)} />
          </Form.Item>
        )
        : (
          <Form.Item label='Clients' name='customers'>
            <CustomerMultiSelect value={customers} onChange={value => setCustomers(value)} />
          </Form.Item>
        )
      }
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={errorMessage ? 'error' : 'validating'}
        help={errorMessage}
      >
        <Button type='primary' htmlType='submit'>
          Créer
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AddInfraForm