import { combineReducers } from 'redux';
import addCustomerReducer from './slices/add';
import deleteCustomerReducer from './slices/delete';
import getCustomersReducer from './slices/get';
import updateCustomerReducer from './slices/update';

const customersReducer = combineReducers({
  get: getCustomersReducer,
  add: addCustomerReducer,
  update: updateCustomerReducer,
  delete: deleteCustomerReducer,
})

export default customersReducer