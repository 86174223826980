type Labels = {
  name: { [key: string]: string }
  get: { [key: string]: string }
  table: { [key: string]: string }
}

const eventsLabels: Labels = {
  name: {
    singular: `Événement`,
    plural: `Événements`,
  },
  get: {
    error: `La récupération des événements a échoué, merci de réessayer plus tard`
  },
  table: {
    empty: 'Aucun événement'
  }
}

export default eventsLabels