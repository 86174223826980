import { LoginOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React, { FC } from 'react'

type Props = {
  machine: string
  domain: string
}

const InfraGoToLTsButton: FC<Props> = ({ machine, domain }) => {

  const handleClick = () => {
    window.open(`https://${machine}.${domain}:9990/openlts/v1/users`, '_blank')
    return null
  }

  return (
    <Tooltip
      title='Aller sur LTS'
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
        style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
        size='small'
        icon={<LoginOutlined />}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default InfraGoToLTsButton