import axios from 'axios'
import config from '../../config/config'
import { errorWrapper } from '../../utils/api'
import { User } from '../customers-users'

const url = `${config.serverBaseUrl}`

class AuthApi {
  static login = (email: string, password: string) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(
        `${url}/login`, { email, password }
      )
      return data
    }
  )

  static logout = () => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(
        `${url}/logout`
      )
      return data
    }
  )

  static whoAmI = () => errorWrapper(
    async (): Promise<User> => {
      const { data } = await axios.get(
        `${url}/core/whoami`
      )
      return data
    }
  )
}



export default AuthApi