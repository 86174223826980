import { LogoutOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import useRouting from '../../../../hooks/useRouting'
import { logout } from '../../slices/logout'

const LogoutButton: FC = () => {
  const dispatch = useDispatch()
  const { goBackToLogin } = useRouting()

  const handleLogoutClick = () => {
    dispatch(logout())
    goBackToLogin()
  }

  return (
    <Tooltip
      title='Déconnexion'
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
        icon={<LogoutOutlined />}
        type='text'
        onClick={handleLogoutClick}
      />
    </Tooltip>
  )
}

export default LogoutButton