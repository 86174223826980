import React, { FC } from 'react';
import Breadcrumb from '../../../../app/Breadcrumb';
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem';
import useRouting from '../../../../hooks/useRouting';
import eventsIcons from './icons';
import eventsLabels from './labels';

const EventsBreadcrumb: FC = ({ children }) => {
  const { goToEvents } = useRouting()

  return (
    <Breadcrumb>
      <BreadcrumbItem
        label={eventsLabels.name.plural}
        icon={eventsIcons.default}
        onClick={goToEvents}
      />
      {children}
    </Breadcrumb>
  )
}

export default EventsBreadcrumb