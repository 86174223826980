import { Button, Tooltip } from 'antd'
import React, { FC } from 'react'
import useRouting from '../../../hooks/useRouting'
import { Id } from '../../../types/common'

type Props = {
  id: Id
}

const InfraEditButton: FC<Props> = ({ id }) => {
  const { goToInfraDetails } = useRouting()

  return (
    <Tooltip
      title='Éditer'
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
        style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
        size='small'
        icon={<i className="ri-edit-2-fill" />}
        onClick={() => goToInfraDetails(id)}
      />
    </Tooltip>
  )
}

export default InfraEditButton