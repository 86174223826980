import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import config from '../config/config'
import { consumeError, useWatcherSelector } from '../features/watcher/watcher'
import useInterval from '../hooks/useInterval'
import useRefreshSession from '../hooks/useRefreshSession'
import useRouting from '../hooks/useRouting'
import Router from '../router/Router'
import './App.less'

const App: FC = () => (
  <BrowserRouter>
    <Content />
  </BrowserRouter>
)

const Content: FC = () => {
  const dispatch = useDispatch()
  const { goToExpired } = useRouting()
  const { error } = useWatcherSelector()
  const refreshSession = useRefreshSession()

  useEffect(() => {
    if (error?.statusCode === 401) {
      goToExpired()
      dispatch(consumeError())
    }
  }, [dispatch, goToExpired, error])

  useInterval(refreshSession, config.intervals.refreshSession)
  useEffect(refreshSession, [refreshSession])

  return <Router />
}

export default App