import { HomeOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import 'antd/dist/antd.css';
import React, { FC, useState } from 'react';
import LogoutButton from '../features/auth/components/logout/Button';
import customersIcons from '../features/customers/components/common/icons';
import eventsIcons from '../features/events/components/common/icons';
import eventsLabels from '../features/events/components/common/labels';
import infrasIcons from '../features/infras/components/common/icons';
import useRouting from '../hooks/useRouting';
import Icon from './Icon';
import Logo from './Logo';

const { Content, Sider } = AntdLayout
const { Item } = Menu

const Layout: FC = ({ children }) => {
  const {
    goToHome,
    goToCustomers,
    goToInfras,
    goToEvents,
  } = useRouting()
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <Sider width={225} collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
        {collapsed ? <Icon /> : <Logo />}
        <Menu theme='dark' mode='inline' defaultOpenKeys={['common']}>
          <Item key='home' icon={<HomeOutlined />} onClick={goToHome}>
            Accueil
          </Item>
          <Item key='infras' icon={<infrasIcons.default />} onClick={goToInfras}>
            Serveurs
          </Item>
          <Item key='customers' icon={<customersIcons.default />} onClick={goToCustomers}>
            Clients
          </Item>
          <Item key='events' icon={<eventsIcons.default />} onClick={goToEvents}>
            {eventsLabels.name.plural}
          </Item>
        </Menu>
      </Sider>
      <AntdLayout className='site-layout'>
        <div className='header' style={{
          padding: '16px',
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <div></div>
          <div>
            <LogoutButton />
          </div>
        </div>
        <Content style={{ margin: '0 16px' }}>
          {children}
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
}

export default Layout