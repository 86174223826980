import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { FC } from 'react'
import { useParams } from 'react-router'
import useRouting from '../../../../hooks/useRouting'
import { User } from '../../types'
import customersUsersLabels from '../common/labels'

export type UsersTableRow = User & {
  key: number
}

type UserTableProps = {
  rows: UsersTableRow[]
}

const UserTable: FC<UserTableProps> = ({ rows }) => {
  const { customerId } = useParams() as { customerId: string }
  const { goToUserDetails } = useRouting()

  const columns: ColumnsType<UsersTableRow> = [
    {
      title: 'Nom',
      dataIndex: 'username',
      key: 'username',
      sorter: (a: UsersTableRow, b: UsersTableRow) => a.username < b.username ? -1 : 1,
      showSorterTooltip: false,
      render: (username: string) => username
    },
    {
      title: 'Prénom',
      dataIndex: 'firstname',
      key: 'firstname',
      sorter: (a: UsersTableRow, b: UsersTableRow) => a.firstname < b.firstname ? -1 : 1,
      showSorterTooltip: false,
      render: (firstname: string) => firstname
    },
    {
      title: 'Nom',
      dataIndex: 'lastname',
      key: 'lastname',
      sorter: (a: UsersTableRow, b: UsersTableRow) => a.lastname < b.lastname ? -1 : 1,
      showSorterTooltip: false,
      render: (lastname: string) => lastname
    },
  ]

  const onRow = ({ id }: UsersTableRow) => ({
    onClick: () => goToUserDetails(customerId, id)
  })

  return (
    <Table
      size='small'
      columns={columns}
      dataSource={rows}
      onRow={onRow}
      pagination={{ defaultPageSize: 15 }}
      locale={{
        filterReset: 'Réinitialiser',
        emptyText: customersUsersLabels.table.empty
      }}
    />
  )
}

export default UserTable