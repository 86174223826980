import { useSelector } from 'react-redux'
import { makeDeleteSlice } from 'redux-helper'
import { RootState } from '../../../redux/rootReducer'
import { AppThunk } from '../../../redux/store'
import { ApiError } from '../../../types/api'
import { Id } from '../../../types/common'
import { watchError } from '../../watcher/watcher'
import CustomersUsersApi from '../api'
import { getUsers } from './get'

const {
  reducer: deleteUserReducer,
  actions: { start, success, error, reset },

} = makeDeleteSlice('deleteUser')
export default deleteUserReducer

export const deleteUser = (customer: Id, id: Id): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      await CustomersUsersApi.deleteUser(customer, id)
      dispatch(success())
      dispatch(getUsers(customer))
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(error(err as ApiError))
    }
    dispatch(reset())
  }

export const useDeleteUserSelector = () =>
  useSelector((state: RootState) => state.customersUsers.delete)