import React, { FC } from 'react'
import Page from '../../../../app/Page'
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem'
import InfrasBreadcrumb from '../common/Breadcrumb'
import infrasIcons from '../common/icons'
import infrasLabels from '../common/labels'
import AddInfraForm from './Form'

const AddInfraPage: FC = () => {
  return (
    <Page title={infrasLabels.add.button} breadcrumb={Breadcumb}>
      <AddInfraForm />
    </Page>
  )
}

const Breadcumb: FC = () => (
  <InfrasBreadcrumb>
    <BreadcrumbItem
      label={infrasLabels.add.button}
      icon={infrasIcons.add}
    />
  </InfrasBreadcrumb>
)

export default AddInfraPage