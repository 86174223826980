import { Button, Popconfirm, Tooltip } from 'antd'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Id } from '../../../types/common'
import { deleteInfra, useDeleteInfraSelector } from '../slices/delete'
import { InfraStatus } from '../types'

type Props = {
  id: Id
  status: InfraStatus
}

const InfraDeleteButton: FC<Props> = ({ id, status }) => {
  const dispatch = useDispatch()

  const { loading } = useDeleteInfraSelector(id)

  const handleClick = () => {
    dispatch(deleteInfra(id))
  }

  return (
    <Tooltip
      title='Supprimer'
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Popconfirm
        title='Confirmer la suppression ?'
        placement='leftTop'
        onConfirm={handleClick}
        okText='Oui'
        cancelText='Non'
      >
        <Button
          style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
          size='small'
          loading={loading}
          icon={<i className="ri-delete-bin-fill" />}
        />
      </Popconfirm>
    </Tooltip>
  )
}

export default InfraDeleteButton