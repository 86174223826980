import { Button, Form, Input, message } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useRouting from '../../../../hooks/useRouting'
import formRules from '../../../../utils/rules'
import { updateCustomer, useUpdateCustomerSelector } from '../../slices/update'
import { Customer } from '../../types'
import customersLabels from '../common/labels'

type CustomerDetailsFormProps = {
  initialValues: Customer
}

const CustomerDetailsForm: FC<CustomerDetailsFormProps> = ({ initialValues }) => {
  const dispatch = useDispatch()
  const { goBack } = useRouting()
  const { customerId } = useParams() as { customerId: string }
  const [name, setName] = useState<string>(initialValues.name)
  const [alias, setAlias] = useState<string>(initialValues.alias)
  const [errorMessage, setErrorMessage] = useState('')

  const { success, error } = useUpdateCustomerSelector()

  useEffect(() => {
    if (success) {
      goBack()
      message.success(customersLabels.update.success)
    } else if (error) {
      const errorMessageMap: { [code: number]: string } = {
        400: 'Adresse email déjà utilisée',
      }
      const message = errorMessageMap[error.statusCode] || `Erreur interne, merci de réessayer plus tard`
      setErrorMessage(message)
    }
  }, [success, error, goBack, dispatch])

  const handleFinish = () => {
    setErrorMessage('')
    dispatch(updateCustomer({
      id: customerId, name, alias
    }))
  }

  return (
    <Form
      layout='vertical'
      onFinish={handleFinish}
      initialValues={initialValues}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 11 }}
    >
      <Form.Item
        label='Nom'
        name='name'
        validateTrigger='onBlur'
        rules={[formRules.required]}
      >
        <Input value={name} onChange={e => setName(e.target.value)} />
      </Form.Item>
      <Form.Item
        label='Alias'
        name='alias'
        validateTrigger='onBlur'
        rules={[formRules.required]}
      >
        <Input value={alias} onChange={e => setAlias(e.target.value)} />
      </Form.Item>
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={errorMessage ? 'error' : 'validating'}
        help={errorMessage}
      >
        <Button type='primary' htmlType='submit'>
          Éditer
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CustomerDetailsForm