import { UserAddOutlined, UserOutlined } from '@ant-design/icons'
import { FC } from 'react'

type Icons = {
  default: FC
  add: FC
  details: FC
}

const customersUsersIcons: Icons = {
  default: UserOutlined,
  add: UserAddOutlined,
  details: UserOutlined,
}

export default customersUsersIcons