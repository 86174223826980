import React, { FC } from 'react'
import Page from '../../../../app/Page'
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem'
import CustomersBreadcrumb from '../common/Breadcrumb'
import customersIcons from '../common/icons'
import customersLabels from '../common/labels'
import AddCustomerForm from './Form'

const AddCustomerPage: FC = () => {
  return (
    <Page title={customersLabels.add.button} breadcrumb={Breadcumb}>
      <AddCustomerForm />
    </Page>
  )
}

const Breadcumb: FC = () => (
  <CustomersBreadcrumb>
    <BreadcrumbItem
      label={customersLabels.add.button}
      icon={customersIcons.add}
    />
  </CustomersBreadcrumb>
)

export default AddCustomerPage