import { default as React, FC } from 'react'
import PageLayout from '../../../../app/PageLayout'
import UsersBreadcrumb from '../common/Breadcrumb'
import UsersSection from './Section'

const UsersPage: FC = () => {return (
    <PageLayout
      breadcrumb={UsersBreadcrumb}
    >
      <UsersSection />
    </PageLayout>
  )
}

export default UsersPage