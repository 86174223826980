import { Tag } from 'antd'
import React, { FC } from 'react'
import { InfraStatus } from '../types'

type Props = {
  status: InfraStatus
}

const InfraStatusTag: FC<Props> = ({ status }) => {
  const offline = { label: 'Hors ligne', color: 'red' }
  const suspended = { label: 'Suspendu', color: 'volcano' }
  const online = { label: 'En ligne', color: 'green' }

  const { label, color } = (() => {
    if ([InfraStatus.Online, InfraStatus.Locked].includes(status)) {
      return online
    } else if (status === InfraStatus.Suspended) {
      return suspended
    } else {
      return offline
    }
  })()

  return (
    <Tag color={color} style={{ margin: 0 }}>
      {label}
    </Tag>
  )
}

export default InfraStatusTag