import { Button } from 'antd'
import { default as React, FC, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Page from '../../../../app/Page'
import AsyncContent from '../../../../common/AsyncContent'
import useInterval from '../../../../hooks/useInterval'
import useRouting from '../../../../hooks/useRouting'
import { Id } from '../../../../types/common'
import { Customer } from '../../../customers'
import { getCustomers, GetCustomersState, useGetCustomersSelector } from '../../../customers/slices/get'
import { getInfras, GetInfrasState, useGetInfrasSelector } from '../../slices/get'
import InfrasBreadcrumb from '../common/Breadcrumb'
import infrasLabels from '../common/labels'
import InfrasTable, { InfrasTableRow } from './Table'

const InfrasPage: FC = () => {
  const dispatch = useDispatch()
  const { goToNewInfra } = useRouting()

  const { rows, ...state } = useInfrasTableSelector()

  const fetchData = useCallback(() => {
    dispatch(getInfras())
    dispatch(getCustomers())
  }, [dispatch])

  useEffect(fetchData, [fetchData])

  useInterval(fetchData, 20 * 1000)

  return (
    <Page
      title={infrasLabels.name.plural}
      button={
        <Button
          type='primary'
          onClick={goToNewInfra}
        >
          {infrasLabels.add.button}
        </Button>
      }
      breadcrumb={InfrasBreadcrumb}
    >
      <AsyncContent
        {...state}
        data={rows}
        errorDescription={infrasLabels.get.error}
      >
        <InfrasTable rows={rows} />
      </AsyncContent>
    </Page>
  )
}

export default InfrasPage

const useInfrasTableSelector = () => {
  const infrasState = useGetInfrasSelector()
  const customersState = useGetCustomersSelector()
  const rows: InfrasTableRow[] = infrasState.data.allIds
    .map(toInfrasTableRow(infrasState, customersState))
    .sort((a, b) => a.machine < b.machine ? -1 : 1)
  return {
    rows,
    loading: infrasState.loading && customersState.loading,
    error: infrasState.error && customersState.error,
  }
}

const toInfrasTableRow = (infrasState: GetInfrasState, customersState: GetCustomersState) =>
  (infraId: Id, i: number): InfrasTableRow => {
    const infra = infrasState.data.byId[infraId]
    const detailedCustomers: Customer[] = infra.customers.map(id => customersState.data.byId[id])
    return {
      key: i,
      ...infra,
      detailedCustomers
    }
  }