import { Space } from 'antd'
import { default as React, FC } from 'react'
import PageTitle from '../common/PageTitle'

type PageProps = {
  title: string
  button?: JSX.Element
  breadcrumb: FC
}

const Page: FC<PageProps> = ({
  title, breadcrumb: Breadcrumb, button, children
}) => {
  return (
    <Space
      style={{ width: '100%', paddingTop: 16 }}
      size='large'
      direction='vertical'
    >
      {Breadcrumb && <Breadcrumb />}
      <div style={{ padding: 24, background: 'white' }}>
        {title && <PageTitle title={title} button={button} />}
        {children}
      </div>
    </Space>
  )
}

export default Page