import { useSelector } from 'react-redux';
import { makeGetSlice } from 'redux-helper';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ApiError } from '../../../types/api';
import { Id } from '../../../types/common';
import { watchError } from '../../watcher/watcher';
import CustomersUsersApi from '../api';
import { User } from '../types';

const {
  reducer: getUsersReducer,
  actions: { start, success, error, reset },
} = makeGetSlice<User>('getUsers')

export type GetUsersState = ReturnType<typeof getUsersReducer>

export default getUsersReducer

export const getUsers = (customer: Id): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      const users: User[] = await CustomersUsersApi.getUsers(customer)
      dispatch(success(users))
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(error(err as ApiError))
    }
    dispatch(reset())
  }

export const useGetUsersSelector = () =>
  useSelector((state: RootState) => state.customersUsers.get)