import { DownloadOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Id } from '../../../types/common'
import { backupInfra, useBackupInfraSelector } from '../slices/backup'

type Props = {
  id: Id
}

const InfraBackupButton: FC<Props> = ({ id }) => {
  const dispatch = useDispatch()

  const { loading } = useBackupInfraSelector(id)

  const handleClick = () => {
    dispatch(backupInfra(id))
  }

  return (
    <Tooltip
      title={`Télécharger la sauvegarde`}
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
          style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
          size='small'
          loading={loading}
          icon={<DownloadOutlined />}
          onClick={() => handleClick()}
        />
    </Tooltip>
  )
}

export default InfraBackupButton