import { Row, Typography } from 'antd';
import React, { FC } from 'react';

const { Title } = Typography

type SectionTitleProps = {
  title: string
  button?: JSX.Element
  secondary?: boolean
}

const SectionTitle: FC<SectionTitleProps> = ({ title, button: Button, secondary }) => {
  const level = secondary ? 3 : 2

  return (
    <Row justify='space-between'>
      <Title level={level}>{title}</Title>
      {Button}
    </Row>
  )
}

export default SectionTitle