const env: any = process.env

const config = {
  serverBaseUrl: `${env.REACT_APP_SERVER_URL}`,
  coreBaseUrl: env.REACT_APP_CORE_URL,
  intervals: {
    refreshSession: 15 * 1000
  },
  logs: {
    debug: env.REACT_APP_DEBUG
  }
}

export default config