type Labels = {
  name: { [key: string]: string }
  get: { [key: string]: string }
  add: { [key: string]: string }
  update: { [key: string]: string }
  delete: { [key: string]: string }
  details: { [key: string]: string }
  table: { [key: string]: string }
}

const customersUsersLabels: Labels = {
  name: {
    singular: `Utilisateur`,
    plural: `Utilisateurs`,
  },
  get: {
    error: `La récupération des utilisateurs a échoué, merci de réessayer plus tard`
  },
  add: {
    button: `Nouvel utilisateur`,
    success: `Utilisateur créé`,
  },
  update: {
    success: `Utilisateur mis à jour`
  },
  delete: {
    button: `Supprimer`,
    success: `Utilisateur supprimé`
  },
  details: {
    button: `Détails de l'utilisateur`,
  },
  table: {
    empty: 'Aucun utilisateur'
  }
}

export default customersUsersLabels