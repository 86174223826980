import { ClockCircleOutlined } from '@ant-design/icons'
import { FC } from 'react'

type Icons = {
  default: FC
}

const eventsIcons: Icons = {
  default: ClockCircleOutlined
}

export default eventsIcons