import { Button } from 'antd'
import { default as React, FC, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Page from '../../../../app/Page'
import AsyncContent from '../../../../common/AsyncContent'
import useRouting from '../../../../hooks/useRouting'
import { getCustomers, GetCustomersState, useGetCustomersSelector } from '../../slices/get'
import CustomersBreadcrumb from '../common/Breadcrumb'
import customersLabels from '../common/labels'
import CustomersTable, { CustomersTableRow } from './Table'

const CustomersPage: FC = () => {
  const dispatch = useDispatch()
  const { goToNewCustomer } = useRouting()

  const { rows, ...state } = useCustomersTableSelector()

  const fetchData = useCallback(() => {
    dispatch(getCustomers())
  }, [dispatch])

  useEffect(fetchData, [fetchData])

  return (
    <Page
      title={customersLabels.name.plural}
      button={
        <Button
          type='primary'
          onClick={goToNewCustomer}
        >
          {customersLabels.add.button}
        </Button>
      }
      breadcrumb={CustomersBreadcrumb}
    >
      <AsyncContent
        {...state}
        data={rows}
        errorDescription={customersLabels.get.error}
      >
        <CustomersTable rows={rows} />
      </AsyncContent>
    </Page>
  )
}

export default CustomersPage

const useCustomersTableSelector = () => {
  const customers = useGetCustomersSelector()
  const rows: CustomersTableRow[] =
    customers.data.allIds
      .map(toRow(customers))
  return {
    rows,
    loading: customers.loading,
    error: customers.error,
  }
}

const toRow = (customers: GetCustomersState) =>
  (id: string, index: number): CustomersTableRow => ({
    key: index,
    ...customers.data.byId[id]
  })