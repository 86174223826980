import { Button, Tooltip } from 'antd'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Id } from '../../../types/common'
import { startInfra, useStartInfraSelector } from '../slices/start'
import { InfraStatus } from '../types'

type Props = {
  id: Id
  status: InfraStatus
}

const InfraStartButton: FC<Props> = ({ id, status }) => {
  const dispatch = useDispatch()

  const state = useStartInfraSelector(id)
  const loading = state.loading || [InfraStatus.Starting, InfraStatus.Installing].includes(status)

  const handleClick = () => {
    dispatch(startInfra(id))
  }

  return (
    <Tooltip
      title={`Démarrer`}
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
        style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
        size='small'
        type='primary'
        loading={loading}
        icon={<i className="ri-play-fill" />}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default InfraStartButton