type Labels = {
  name: { [key: string]: string }
  get: { [key: string]: string }
  add: { [key: string]: string }
  update: { [key: string]: string }
  delete: { [key: string]: string }
  details: { [key: string]: string }
  table: { [key: string]: string }
  size: { [key: string]: string }
  dedicated: { [key: string]: string }
}

const infrasLabels: Labels = {
  name: {
    singular: `Serveur`,
    plural: `Serveurs`,
  },
  get: {
    error: `La récupération des serveurs a échoué, merci de réessayer plus tard`
  },
  add: {
    button: `Nouveau serveur`,
    success: `Serveur créé`,
  },
  update: {
    success: `Serveur mis à jour`
  },
  delete: {
    button: `Supprimer`,
    success: `Serveur supprimé`
  },
  details: {
    button: `Détails du serveur`,
  },
  table: {
    empty: 'Aucun serveur'
  },
  size: {
    Small: 'Petite (1 CPU, 2Go Ram)',
    Medium: 'Moyenne (2 CPUs, 4Go Ram)',
    Large: 'Grande (4 CPUs, 16Go Ram)',
    Huge: 'Énorme (8 CPUs, 32Go Ram)',
  },
  dedicated: {
    true: 'Dédié',
    false: 'Partagé',
  }
}

export default infrasLabels