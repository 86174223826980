import React, { FC } from 'react'

const Logo: FC = () => (
  <div style={{ height: 72, padding: '16px 24px' }}>
    <img
      style={{ height: '100%' }}
      alt='Sologic'
      src='/sologic-manager-logo.png'
    />
  </div>
)

export default Logo