import { useSelector } from 'react-redux'
import { makeUpdateSlice } from 'redux-helper'
import { RootState } from '../../../redux/rootReducer'
import { AppThunk } from '../../../redux/store'
import { ApiError } from '../../../types/api'
import { watchError } from '../../watcher/watcher'
import CustomersApi from '../api'
import { CustomerChanges } from '../types'
import { getCustomers } from './get'

const {
  reducer: updateCustomerReducer,
  actions: { start, success, error, reset },
} = makeUpdateSlice('updateCustomer')

export default updateCustomerReducer

export const updateCustomer = (changes: CustomerChanges): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      await CustomersApi.updateCustomer(changes)
      dispatch(success())
      dispatch(getCustomers())
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(error(err as ApiError))
    }
    dispatch(reset())
  }

export const useUpdateCustomerSelector = () =>
  useSelector((state: RootState) => state.customers.update)