import { Button, Form, Input, message, Switch } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useRouting from '../../../../hooks/useRouting'
import formRules from '../../../../utils/rules'
import { updateUser, useUpdateUserSelector } from '../../slices/update'
import { User } from '../../types'
import customersUsersLabels from '../common/labels'

type UserDetailsFormProps = {
  initialValues: User
}

const UserDetailsForm: FC<UserDetailsFormProps> = ({ initialValues }) => {
  const dispatch = useDispatch()
  const { customerId } = useParams() as { customerId: string }
  const { goBack } = useRouting()
  const { userId } = useParams() as { userId: string }
  const [firstname, setFirstname] = useState<string>(initialValues.firstname)
  const [lastname, setLastname] = useState<string>(initialValues.lastname)
  const [password, setPassword] = useState<string>()
  const [disabled, setDisabled] = useState<boolean>(initialValues.disabled)
  const [errorMessage, setErrorMessage] = useState('')

  const { success, error } = useUpdateUserSelector()

  useEffect(() => {
    if (success) {
      goBack()
      message.success(customersUsersLabels.update.success)
    } else if (error) {
      const message = `Erreur interne, merci de réessayer plus tard`
      setErrorMessage(message)
    }
  }, [success, error, goBack, dispatch])

  const handleFinish = () => {
    setErrorMessage('')
    dispatch(updateUser(customerId, {
      id: userId, firstname, lastname, password, disabled
    }))
  }

  return (
    <Form
      layout='vertical'
      onFinish={handleFinish}
      initialValues={initialValues}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 11 }}
    >
      <Form.Item
        label='Prénom'
        name='firstname'
        validateTrigger='onBlur'
        rules={[formRules.required]}
      >
        <Input value={firstname} onChange={e => setFirstname(e.target.value)} />
      </Form.Item>
      <Form.Item
        label='Nom'
        name='lastname'
        validateTrigger='onBlur'
        rules={[formRules.required]}
      >
        <Input value={lastname} onChange={e => setLastname(e.target.value)} />
      </Form.Item>
      <Form.Item label='Mot de passe' name='password'>
        <Input
          type='password'
          placeholder={`Laisser vide pour conserver l'actuel`}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item label='Statut' name='disabled'>
        <Switch
          checked={!disabled}
          checkedChildren='Actif'
          unCheckedChildren='Désactivé'
          onChange={() => setDisabled(!disabled)}
        />
      </Form.Item>
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={errorMessage ? 'error' : 'validating'}
        help={errorMessage}
      >
        <Button type='primary' htmlType='submit'>
          Éditer
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UserDetailsForm