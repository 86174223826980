import { Id } from '../../types/common';

export enum InfraStatus {
  Offline = 'Offline',
  Suspended = 'Suspended',
  Creating = 'Creating',
  Starting = 'Starting',
  Installing = 'Installing',
  Online = 'Online',
  Locked = 'Locked',
  Stopping = 'Stopping',
}

export enum InfraSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  Huge = 'Huge',
}

export type Infra = {
  id: Id
  customers: Id[]
  machine: string
  domain: string
  size: InfraSize
  dedicated: boolean
  status: InfraStatus
  onlineSince?: string
  lastBackup?: string
}

export type NewInfra = {
  machine: string
  size: InfraSize
  dedicated: boolean
  customers: Id[]
}

export type InfraChanges = {
  id: Id
  machine: string
  size: InfraSize
  dedicated: boolean
  customers: Id[]
}