import { useSelector } from 'react-redux'
import { makeAddSlice } from 'redux-helper'
import { RootState } from '../../../redux/rootReducer'
import { AppThunk } from '../../../redux/store'
import { ApiError } from '../../../types/api'
import { watchError } from '../../watcher/watcher'
import InfrasApi from '../api'
import { NewInfra } from '../types'
import { getInfras } from './get'

const {
  reducer: addInfraReducer,
  actions: { start, success, error, reset },
} = makeAddSlice('addInfra')

export default addInfraReducer

export const addInfra = (infra: NewInfra): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      await InfrasApi.createInfra(infra)
      dispatch(success())
      dispatch(getInfras())
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(error(err as ApiError))
    }
    dispatch(reset())
  }

export const useAddInfraSelector = () =>
  useSelector((state: RootState) => state.infras.add)