import axios from 'axios'
import config from '../../config/config'
import { errorWrapper } from '../../utils/api'
import { Event } from '../events'

const url = `${config.serverBaseUrl}/manager/events`

class EventsApi {
  static getEvents = () => errorWrapper(
    async (): Promise<Event[]> => {
      const { data } = await axios.get(url)
      return data
    }
  )
}

export default EventsApi