import { FC } from 'react'
import { DatabaseOutlined } from '@ant-design/icons';

type Icons = {
  default: FC
  add: FC
  details: FC
}

const infrasIcons: Icons = {
  default: DatabaseOutlined,
  add: DatabaseOutlined,
  details: DatabaseOutlined,
}

export default infrasIcons