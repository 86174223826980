import { combineReducers } from 'redux';
import addUserReducer from './slices/add';
import deleteUserReducer from './slices/delete';
import getUsersReducer from './slices/get';
import updateUserReducer from './slices/update';

const customersUsersReducer = combineReducers({
  get: getUsersReducer,
  add: addUserReducer,
  update: updateUserReducer,
  delete: deleteUserReducer,
})

export default customersUsersReducer