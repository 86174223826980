import { useSelector } from 'react-redux'
import { makeAddSlice } from 'redux-helper'
import { RootState } from '../../../redux/rootReducer'
import { AppThunk } from '../../../redux/store'
import { ApiError } from '../../../types/api'
import { Id } from '../../../types/common'
import { watchError } from '../../watcher/watcher'
import CustomersUsersApi from '../api'
import { NewUser } from '../types'
import { getUsers } from './get'

const {
  reducer: addUserReducer,
  actions: { start, success, error, reset },
} = makeAddSlice('addUser')

export default addUserReducer

export const addUser = (customer: Id, user: NewUser): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      await CustomersUsersApi.createUser(customer, user)
      dispatch(success())
      dispatch(getUsers(customer))
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(error(err as ApiError))
    }
    dispatch(reset())
  }

export const useAddUserSelector = () =>
  useSelector((state: RootState) => state.customersUsers.add)