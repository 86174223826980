import React, { FC } from 'react';
import Breadcrumb from '../../../../app/Breadcrumb';
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem';
import useRouting from '../../../../hooks/useRouting';
import infrasIcons from './icons';
import infrasLabels from './labels';

const InfrasBreadcrumb: FC = ({ children }) => {
  const { goToInfras } = useRouting()

  return (
    <Breadcrumb>
      <BreadcrumbItem
        label={infrasLabels.name.plural}
        icon={infrasIcons.default}
        onClick={goToInfras}
      />
      {children}
    </Breadcrumb>
  )
}

export default InfrasBreadcrumb