import { LockFilled } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Id } from '../../../types/common'
import { unlockInfra, useUnlockInfraSelector } from '../slices/unlock'

type Props = {
  id: Id
}

const InfraUnlockButton: FC<Props> = ({ id }) => {
  const dispatch = useDispatch()

  const { loading } = useUnlockInfraSelector(id)

  const handleClick = () => {
    dispatch(unlockInfra(id))
  }

  return (
    <Tooltip
      title={`Déverrouiller`}
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
        style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
        size='small'
        loading={loading}
        icon={<LockFilled />}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default InfraUnlockButton