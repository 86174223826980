import { Select } from 'antd'
import React, { FC } from 'react'
import { InfraSize } from '../../types'
import infrasLabels from '../common/labels'

type InfraSizeSelectProps = {
  value?: InfraSize
  onChange: (value: InfraSize) => void
}

const InfraSizeSelect: FC<InfraSizeSelectProps> =
  React.forwardRef((props, ref) => {
    return (
      <Select
        style={{ width: '100%' }}
        {...props}
      >
        {Object.keys(InfraSize).map((key: string) => (
          <Select.Option key={key} value={key}>{infrasLabels.size[key]}</Select.Option>
        ))}
      </Select>
    )
  })

export default InfraSizeSelect