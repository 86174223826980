import axios from 'axios'
import config from '../../config/config'
import { Id } from '../../types/common'
import { errorWrapper } from '../../utils/api'
import { User } from '.'
import { NewUser, UserChanges } from './types'

const url = `${config.serverBaseUrl}/manager`

class CustomersUsersApi {
  static getUsers = (customer: Id) => errorWrapper(
    async (): Promise<User[]> => {
      const { data } = await axios.get(`${url}/customers/${customer}/users`)
      return data
    }
  )

  static createUser = (customer: Id, user: NewUser) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(`${url}/customers/${customer}/users`, user)
      return data
    }
  )

  static updateUser = (customer: Id, { id, ...input }: UserChanges) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.put(`${url}/customers/${customer}/users/${id}`, input)
      return data
    }
  )

  static deleteUser = (customer: Id, id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.delete(`${url}/customers/${customer}/users/${id}`)
      return data
    }
  )
}

export default CustomersUsersApi