import { combineReducers } from 'redux';
import logoutReducer from './slices/logout';
import sessionReducer from './slices/session';
import whoAmIReducer from './slices/whoAmI';

const authReducer = combineReducers({
  whoAmI: whoAmIReducer,
  logout: logoutReducer,
  session: sessionReducer,
})

export type AuthState = ReturnType<typeof authReducer>

export default authReducer
