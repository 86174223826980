import { useSelector } from 'react-redux';
import { makeGetSlice } from 'redux-helper';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ApiError } from '../../../types/api';
import { watchError } from '../../watcher/watcher';
import CustomersApi from '../api';
import { Customer } from '../types';

const {
  reducer: getCustomersReducer,
  actions: { start, success, error, reset },
} = makeGetSlice<Customer>('getCustomers')

export type GetCustomersState = ReturnType<typeof getCustomersReducer>

export default getCustomersReducer

export const getCustomers = (): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      const customers: Customer[] = await CustomersApi.getCustomers()
      dispatch(success(customers))
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(error(err as ApiError))
    }
    dispatch(reset())
  }

export const useGetCustomersSelector = () =>
  useSelector((state: RootState) => state.customers.get)