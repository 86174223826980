import { Button, Modal, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { SearchOutlined } from '@ant-design/icons'
import { format } from 'date-fns'
import React, { FC, useState } from 'react'
import { Event } from '../../types'
import eventsLabels from '../common/labels'

export type EventsTableRow = Event & {
  key: number
}

type EventTableProps = {
  rows: EventsTableRow[]
}

const EventTable: FC<EventTableProps> = ({ rows }) => {
  rows.sort((a, b) => a > b ? 1 : -1)

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns: ColumnsType<EventsTableRow> = [
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a: EventsTableRow, b: EventsTableRow) => a.timestamp < b.timestamp ? -1 : 1,
      showSorterTooltip: false,
      render: (timestamp: number) => format(new Date(timestamp), 'dd-MM-yyyy HH:mm')
    },
    {
      title: 'Nom',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: EventsTableRow, b: EventsTableRow) => a.name < b.name ? -1 : 1,
      showSorterTooltip: false,
      render: (name: string) => name
    },
    {
      title: 'Détails',
      dataIndex: 'details',
      key: 'details',
      align: 'right',
      render: (_, { name, details }) => {
        return details &&
          <>
            <Button type="text" icon={<SearchOutlined />} onClick={showModal} />
            <Modal title={name} visible={isModalVisible} onCancel={handleCancel} footer={null}>
              {/* {details.split('\n').map((line, i) => {
                const tabs = line.split('\t').length
                return <Typography.Paragraph style={{ paddingLeft: tabs * 20 }} key={i}>{line}</Typography.Paragraph>
              })} */}
              <code>{details}</code>
            </Modal>
          </>
      }
    },
  ]

  return (
    <Table
      size='small'
      columns={columns}
      dataSource={rows}
      pagination={{ defaultPageSize: 15 }}
      locale={{
        filterReset: 'Réinitialiser',
        emptyText: eventsLabels.table.empty
      }}
    />
  )
}

export default EventTable