import axios from 'axios'
import config from '../../config/config'
import { Id } from '../../types/common'
import { errorWrapper } from '../../utils/api'
import { Customer } from '../customers'
import { NewCustomer, CustomerChanges } from './types'

const url = `${config.serverBaseUrl}/manager/customers`

class CustomersApi {
  static getCustomers = () => errorWrapper(
    async (): Promise<Customer[]> => {
      const { data } = await axios.get(url)
      return data
    }
  )

  static createCustomer = (customer: NewCustomer) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.post(url, customer)
      return data
    }
  )

  static updateCustomer = ({ id, ...changes }: CustomerChanges) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.put(`${url}/${id}`, changes)
      return data
    }
  )

  static deleteCustomer = (id: Id) => errorWrapper(
    async (): Promise<string> => {
      const { data } = await axios.delete(`${url}/${id}`)
      return data
    }
  )
}

export default CustomersApi