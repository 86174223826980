import { message } from 'antd'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Page from '../../../../app/Page'
import AsyncContent from '../../../../common/AsyncContent'
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem'
import DeleteButton from '../../../../common/DeleteButton'
import useRouting from '../../../../hooks/useRouting'
import { deleteUser, useDeleteUserSelector } from '../../slices/delete'
import { getUsers, useGetUsersSelector } from '../../slices/get'
import UsersBreadcrumb from '../common/Breadcrumb'
import customersUsersIcons from '../common/icons'
import customersUsersLabels from '../common/labels'
import UserDetailsForm from './Form'

const UserDetailsPage: FC = () => {
  const dispatch = useDispatch()
  const { customerId } = useParams() as { customerId: string }
  const { userId } = useParams() as { userId: string }
  const { goBack } = useRouting()

  const { data: { user }, ...state } = useUserDetailsPageSelector(userId)
  const { success } = useDeleteUserSelector()

  const handleDeleteUser = () => {
    dispatch(deleteUser(customerId, userId))
  }

  useEffect(() => {
    if (success) {
      goBack()
      message.success(customersUsersLabels.delete.success)
    }
  }, [success, dispatch, goBack])

  useEffect(() => {
    dispatch(getUsers(customerId))
  }, [customerId, dispatch])

  return (
    <Page
      title={customersUsersLabels.details.button}
      button={
        <DeleteButton
          type='primary'
          danger
          onClick={handleDeleteUser}
        >
          {customersUsersLabels.delete.button}
        </DeleteButton>
      }
      breadcrumb={Breadcrumb}
    >
      <AsyncContent
        {...state}
        data={user}
        errorDescription={`Echec du chargement de l'utilisateur`}
      >
        <UserDetailsForm initialValues={user} />
      </AsyncContent>
    </Page>
  )
}

const Breadcrumb = () => (
  <UsersBreadcrumb>
    <BreadcrumbItem
      label={customersUsersLabels.details.button}
      icon={customersUsersIcons.details}
    />
  </UsersBreadcrumb>
)

export default UserDetailsPage

const useUserDetailsPageSelector = (id: string) => {
  const users = useGetUsersSelector()
  const user = users.data.byId[id]
  return {
    data: {
      user,
    },
    loading: users.loading,
    error: users.error,
  }
}