import { useHistory } from 'react-router-dom'
import { Id } from '../types/common'

const useRouting = () => {
  const { push, goBack } = useHistory()

  return {
    goToHome: () => push(`/`),

    goToCustomers: () => push(`/customers`),
    goToNewCustomer: () => push(`/customers/new`),
    goToCustomerDetails: (id: Id) => push(`/customers/${id}`),

    goToUsers: (customer: Id) => push(`/customers/${customer}/users`),
    goToNewUser: (customer: Id) => push(`/customers/${customer}/users/new`),
    goToUserDetails: (customer: Id, id: Id) => push(`/customers/${customer}/users/${id}`),

    goToInfras: () => push(`/infras`),
    goToNewInfra: () => push(`/infras/new`),
    goToInfraDetails: (id: Id) => push(`/infras/${id}`),

    goToEvents: () => push(`/events`),

    goToForbidden: () => push(`/forbidden`),
    goToNotFound: () => push(`/notFound`),

    goBackToLogin: () => push('/logout'),
    goBackToServices: () => push('/services'),
    goToExpired: () => push('/expired'),

    goBack,
  }
}

export default useRouting