import { Button, message, Tooltip } from 'antd'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Id } from '../../../types/common'
import { syncInfra, useSyncInfraSelector } from '../slices/sync'

type Props = {
  id: Id
}

const InfraSyncButton: FC<Props> = ({ id }) => {
  const dispatch = useDispatch()

  const { loading, success } = useSyncInfraSelector(id)

  const handleClick = () => {
    dispatch(syncInfra(id))
  }

  useEffect(() => {
    if (success) {
      message.success('Synchronisation réussie')
    }
  }, [success])

  return (
    <Tooltip
      title={`Synchroniser`}
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
        style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
        size='small'
        loading={loading}
        icon={<i className="ri-refresh-line" />}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default InfraSyncButton