import { useSelector } from 'react-redux';
import { makeAsyncStateSlice } from 'redux-helper';
import { ApiError } from 'redux-helper/lib/types';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import AuthApi from '../api';
import { closeSession } from './session';

const {
  reducer: logoutReducer,
  actions: { start, success, error, reset }
} = makeAsyncStateSlice('logout')

export default logoutReducer

export const logout = (): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      await AuthApi.logout()
      dispatch(success())
    } catch (err) {
      dispatch(error(err as ApiError))
    }
    dispatch(closeSession())
    reset()
  }

export const useLogoutSelector = () =>
  useSelector((state: RootState) => state.auth.logout)