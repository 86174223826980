import { Typography } from 'antd'
import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import AsyncContent from '../common/AsyncContent'
import eventsDico from '../features/events/components/common/labels'
import EventsTable, { EventsTableRow } from '../features/events/components/table/Table'
import { getEvents, GetEventsState, useGetEventsSelector } from '../features/events/slices/get'
import Breadcrumb from './Breadcrumb'
import Page from './Page'

const Home: FC = () => {
  const dispatch = useDispatch()

  const { rows, ...state } = useHomeSelector()

  const fetchData = useCallback(() => {
    dispatch(getEvents())
  }, [dispatch])

  useEffect(fetchData, [fetchData])

  return (
    <Page title='Accueil' breadcrumb={Breadcrumb}>
      <Typography.Title level={5}>Événements récents</Typography.Title>
      <AsyncContent
        {...state}
        data={rows}
        errorDescription={eventsDico.get.error}
      >
        <EventsTable rows={rows} />
      </AsyncContent>
    </Page>
  )
}

export default Home

const useHomeSelector = () => {
  const events = useGetEventsSelector()
  const rows: EventsTableRow[] =
    events.data.allIds
      .map(toRow(events))
  return {
    rows,
    loading: events.loading,
    error: events.error,
  }
}

const toRow = (events: GetEventsState) =>
  (id: string, index: number): EventsTableRow => ({
    key: index,
    ...events.data.byId[id]
  })