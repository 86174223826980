import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { whoAmI } from '../features/auth/slices/whoAmI'

const useRefreshSession = () => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(whoAmI())
  }, [dispatch])
}

export default useRefreshSession