import { useSelector } from 'react-redux'
import { makeDeleteSlice } from 'redux-helper'
import { RootState } from '../../../redux/rootReducer'
import { AppThunk } from '../../../redux/store'
import { ApiError } from '../../../types/api'
import { Id } from '../../../types/common'
import { watchError } from '../../watcher/watcher'
import CustomersApi from '../api'
import { getCustomers } from './get'

const {
  reducer: deleteCustomerReducer,
  actions: { start, success, error, reset },

} = makeDeleteSlice('deleteCustomer')
export default deleteCustomerReducer

export const deleteCustomer = (id: Id): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      await CustomersApi.deleteCustomer(id)
      dispatch(success())
      dispatch(getCustomers())
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(error(err as ApiError))
    }
    dispatch(reset())
  }

export const useDeleteCustomerSelector = () =>
  useSelector((state: RootState) => state.customers.delete)