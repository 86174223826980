import { Button, Result } from 'antd'
import React, { FC } from 'react'

type NotFoundPageProps = {
  onReturn: () => void
}

const NotFoundPage: FC<NotFoundPageProps> = ({ onReturn }) => (
  <Result
    status='404'
    title='404'
    subTitle={`Désolé, la page à laquelle vous essayez d'accéder n'existe pas`}
    extra={
      <Button
        type='primary'
        onClick={onReturn}
      >
        Revenir à l'acceuil
        </Button>
    }
  />
)

export default NotFoundPage