import { Select } from 'antd'
import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Id } from '../../../../types/common'
import { getCustomers, useGetCustomersSelector } from '../../slices/get'
import { Customer } from '../../types'

type CustomerSelectProps = {
  value?: Id
  onChange: (value: Id) => void
}

const CustomerSelect: FC<CustomerSelectProps> =
  React.forwardRef((props, ref) => {
    const dispatch = useDispatch()

    const { data } = useGetCustomersSelector()
    const customers = data.allIds
      .map((id: Id) => data.byId[id])
      .sort((a, b) => a.name > b.name ? 1 : -1)

    useEffect(() => {
      dispatch(getCustomers())
    }, [dispatch])

    return (
      <Select
        style={{ width: '100%' }}
        {...props}
      >
        {customers.map(({ id, name }: Customer) => (
          <Select.Option key={id} value={id}>{name}</Select.Option>
        ))}
      </Select>
    )
  })

export default CustomerSelect