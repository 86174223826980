type Labels = {
  name: { [key: string]: string }
  get: { [key: string]: string }
  add: { [key: string]: string }
  update: { [key: string]: string }
  delete: { [key: string]: string }
  details: { [key: string]: string }
  table: { [key: string]: string }
}

const customersLabels: Labels = {
  name: {
    singular: `Client`,
    plural: `Clients`,
  },
  get: {
    error: `La récupération des clients a échoué, merci de réessayer plus tard`
  },
  add: {
    button: `Nouveau client`,
    success: `Client créé`,
  },
  update: {
    success: `Client mis à jour`
  },
  delete: {
    button: `Supprimer`,
    success: `Client supprimé`
  },
  details: {
    button: `Détails du client`,
  },
  table: {
    empty: 'Aucun client'
  }
}

export default customersLabels