import { HomeOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import AntdBreadcrumb from '../common/breadcrumb/Breadcrumb';
import BreadcrumbItem from '../common/breadcrumb/BreadcrumbItem';
import useRouting from '../hooks/useRouting';

const Breadcrumb: FC = ({ children }) => {
  const { goToHome } = useRouting()

  return (
    <AntdBreadcrumb>
      <BreadcrumbItem
        label='Accueil'
        icon={HomeOutlined}
        onClick={goToHome}
      />
      {children}
    </AntdBreadcrumb>
  )
}

export default Breadcrumb