import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, message, Space, Tooltip } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useRouting from '../../../../hooks/useRouting'
import { Id } from '../../../../types/common'
import CustomerMultiSelect from '../../../customers/components/select/MultiSelect'
import CustomerSelect from '../../../customers/components/select/Select'
import { updateInfra, useUpdateInfraSelector } from '../../slices/update'
import { Infra, InfraSize } from '../../types'
import infrasLabels from '../common/labels'

type InfraDetailsFormProps = {
  initialValues: Infra
}

const InfraDetailsForm: FC<InfraDetailsFormProps> = ({ initialValues }) => {
  const dispatch = useDispatch()
  const { goBack } = useRouting()
  const { infraId } = useParams() as { infraId: string }
  const [machine] = useState<string>(initialValues.machine)
  const [size] = useState<InfraSize>(initialValues.size)
  const [customers, setCustomers] = useState<Id[]>(initialValues.customers)
  const [customer, setCustomer] = useState<Id>(initialValues.customers[0])
  const [dedicated, setDedicated] = useState<boolean>(initialValues.dedicated)
  const [errorMessage, setErrorMessage] = useState('')

  const { success, error } = useUpdateInfraSelector(infraId)

  useEffect(() => {
    if (success) {
      goBack()
      message.success(infrasLabels.update.success)
    } else if (error) {
      const errorMessageMap: { [code: number]: string } = {
        400: 'Adresse email déjà utilisée',
      }
      const message = errorMessageMap[error.statusCode] || `Erreur interne, merci de réessayer plus tard`
      setErrorMessage(message)
    }
  }, [success, error, goBack, dispatch])

  const handleFinish = () => {
    setErrorMessage('')
    dispatch(updateInfra({
      id: infraId, machine, size, dedicated,
      customers: dedicated ? [customer] : customers
    }))
  }

  return (
    <Form
      layout='vertical'
      onFinish={handleFinish}
      initialValues={{ ...initialValues, customer }}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 11 }}
    >
      <Form.Item
        label={(
          <Space align='center' size='small'>
            <span>Nom</span>
            <Tooltip
              placement='right'
              title='Le nom du serveur détermine le nom de domaine à partir duquel il est accessible'
              mouseEnterDelay={1}
              mouseLeaveDelay={0}
            >
              <QuestionCircleOutlined style={{ color: '#999' }} />
            </Tooltip>
          </Space>
        )}
        name='machine'
      >
        <Input readOnly value={machine}/>
      </Form.Item>
      <Form.Item label='Taille' name='size'>
        <Input readOnly value={size}/>
      </Form.Item>
      <Form.Item name='dedicated'>
        <Checkbox checked={dedicated} onChange={e => { setDedicated(e.target.checked) }}>
          Dédié
        </Checkbox>
      </Form.Item>
      {dedicated
        ? (
          <Form.Item label='Client' name='customer'>
            <CustomerSelect value={customer} onChange={value => setCustomer(value)} />
          </Form.Item>
        )
        : (
          <Form.Item label='Clients' name='customers'>
            <CustomerMultiSelect value={customers} onChange={value => setCustomers(value)} />
          </Form.Item>
        )
      }
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={errorMessage ? 'error' : 'validating'}
        help={errorMessage}
      >
        <Button type='primary' htmlType='submit'>
          Éditer
        </Button>
      </Form.Item>
    </Form>
  )
}

export default InfraDetailsForm