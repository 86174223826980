import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import { Id } from '../../../../types/common';
import { Customer } from '../../../customers';
import { Infra, InfraSize, InfraStatus } from '../../types';
import InfraBackupButton from '../BackupButton';
import infrasLabels from '../common/labels';
import InfraDeleteButton from '../DeleteButton';
import InfraEditButton from '../EditButton';
import InfraGoToLTsButton from '../GoToLtsButton';
import InfraLockButton from '../LockButton';
import InfraResumeButton from '../ResumeButton';
import InfraStartButton from '../StartButton';
import InfraStatusTag from '../StatusTag';
import InfraStopButton from '../StopButton';
import InfraSuspendButton from '../SuspendButton';
import InfraSyncButton from '../SyncButton';
import InfraUnlockButton from '../UnlockButton';

export type InfrasTableRow = Infra & {
  key: number
  detailedCustomers: Customer[]
}

type InfraTableProps = {
  rows: InfrasTableRow[]
}

const InfraTable: FC<InfraTableProps> = ({ rows }) => {
  const columns: ColumnsType<InfrasTableRow> = [
    {
      title: 'Nom',
      dataIndex: 'machine',
      key: 'machine',
      sorter: (a: InfrasTableRow, b: InfrasTableRow) => a.machine < b.machine ? -1 : 1,
      showSorterTooltip: false,
      render: (machine: string) => machine
    },
    {
      title: 'Type',
      dataIndex: 'dedicated',
      key: 'dedicated',
      sorter: (a: InfrasTableRow, b: InfrasTableRow) => a.dedicated < b.dedicated ? -1 : 1,
      showSorterTooltip: false,
      render: (dedicated: boolean) => infrasLabels.dedicated[dedicated.toString()]
    },
    {
      title: 'Taille',
      dataIndex: 'size',
      key: 'size',
      sorter: (a: InfrasTableRow, b: InfrasTableRow) => a.size < b.size ? -1 : 1,
      showSorterTooltip: false,
      render: (size: InfraSize) => infrasLabels.size[size]
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: InfrasTableRow, b: InfrasTableRow) => a.status < b.status ? -1 : 1,
      showSorterTooltip: false,
      render: (status: InfraStatus) => <InfraStatusTag status={status} />
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      sorter: (a: InfrasTableRow, b: InfrasTableRow) => a.id < b.id ? -1 : 1,
      showSorterTooltip: false,
      align: 'right',
      render: (id: Id, infra) => {
        const { lastBackup, status } = infra
        return (
          <Space>
            {lastBackup && [InfraStatus.Online, InfraStatus.Locked].includes(status) && <InfraBackupButton {...infra} />}
            {[InfraStatus.Online, InfraStatus.Locked].includes(status) && <InfraGoToLTsButton {...infra} />}
            {[InfraStatus.Online].includes(status) && <InfraLockButton {...infra} />}
            {[InfraStatus.Locked].includes(status) && <InfraUnlockButton {...infra} />}
            {[InfraStatus.Online, InfraStatus.Locked].includes(status) && <InfraSyncButton {...infra} />}
            {/* {status !== InfraStatus.Locked && <InfraToggleButton {...infra} />} */}
            {status === InfraStatus.Offline && <InfraStartButton {...infra} />}
            {status === InfraStatus.Online && <InfraSuspendButton {...infra} />}
            {status === InfraStatus.Online && <InfraStopButton {...infra} />}
            {status === InfraStatus.Suspended && <InfraResumeButton {...infra} />}
            <InfraEditButton {...infra} />
            {status === InfraStatus.Offline && <InfraDeleteButton {...infra} />}
          </Space>
        )
      }
    },
  ]

  return (
    <Table
      size='small'
      columns={columns}
      dataSource={rows}
      pagination={{ defaultPageSize: 15 }}
      locale={{
        filterReset: 'Réinitialiser',
        emptyText: infrasLabels.table.empty
      }}
    />
  )
}

export default InfraTable