import { Row, Typography } from 'antd';
import React, { FC } from 'react';

const { Title } = Typography

type PageTitleProps = {
  title: string
  button?: JSX.Element
}

const PageTitle: FC<PageTitleProps> = ({ title, button: Button }) => {
  return (
    <Row justify='space-between'>
      <Title level={2}>{title}</Title>
      {Button}
    </Row>
  )
}

export default PageTitle