import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ApiError } from '../../../types/api';
import { Id } from '../../../types/common';
import { RemoteStateWithSuccess } from '../../../types/redux';
import { watchError } from '../../watcher/watcher';
import InfraApi from '../api';
import { InfraChanges } from '../types';
import { getInfras } from './get';

export type UpdateInfraState = { [id: string]: RemoteStateWithSuccess }

const initialState: UpdateInfraState = {}

const {
  reducer,
  actions,
} = createSlice({
  name: 'updateInfra',
  initialState,
  reducers: {
    start: (state: UpdateInfraState, action: PayloadAction<Id>) => {
      const id = action.payload
      const newState = {
        success: false,
        loading: true,
        error: null,
      }
      state[id] = newState
    },
    success: (state: UpdateInfraState, action: PayloadAction<Id>) => {
      const id = action.payload
      const newState = {
        success: true,
        loading: false,
        error: null,
      }
      state[id] = newState
    },
    error: (state: UpdateInfraState, action: PayloadAction<{ id: Id, error: ApiError }>) => {
      const { id, error } = action.payload
      const newState = {
        success: false,
        loading: false,
        error: error,
      }
      state[id] = newState
    },
    reset: (state: UpdateInfraState, action: PayloadAction<Id>) => {
      const id = action.payload
      const newState = {
        success: false,
        loading: false,
        error: null,
      }
      state[id] = newState
    },
  }
})

export default reducer

export const updateInfra = (changes: InfraChanges): AppThunk =>
  async dispatch => {
    try {
      dispatch(actions.start(changes.id))
      await InfraApi.updateInfra(changes)
      dispatch(actions.success(changes.id))
      dispatch(getInfras())
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(actions.error({ id: changes.id, error: err as ApiError }))
    }
    dispatch(actions.reset(changes.id))
  }

export const useUpdateInfraSelector = (id: Id): RemoteStateWithSuccess =>
  useSelector((state: RootState) => {
    const updateState: RemoteStateWithSuccess | undefined = state.infras.update[id]
    return updateState || initialState
  })