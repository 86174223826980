import { Button } from 'antd'
import { default as React, FC, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import Section from '../../../../app/Section'
import AsyncContent from '../../../../common/AsyncContent'
import useRouting from '../../../../hooks/useRouting'
import { getUsers, GetUsersState, useGetUsersSelector } from '../../slices/get'
import customersUsersLabels from '../common/labels'
import UsersTable, { UsersTableRow } from './Table'

type UsersSectionProps = {
  secondary?: boolean
}

const UsersSection: FC<UsersSectionProps> = ({ secondary }) => {
  const dispatch = useDispatch()
  const { customerId } = useParams() as { customerId: string }
  const { goToNewUser } = useRouting()

  const { rows, ...state } = useUsersSectionSelector()

  const fetchData = useCallback(() => {
    dispatch(getUsers(customerId))
  }, [customerId, dispatch])

  useEffect(fetchData, [fetchData])

  return (
    <Section
      title={customersUsersLabels.name.plural}
      button={
        <Button
          type='primary'
          onClick={() => goToNewUser(customerId)}
        >
          {customersUsersLabels.add.button}
        </Button>
      }
      secondary
    >
      <AsyncContent
        {...state}
        data={rows}
        errorDescription={customersUsersLabels.get.error}
      >
        <UsersTable rows={rows} />
      </AsyncContent>
    </Section>
  )
}

export default UsersSection

const useUsersSectionSelector = () => {
  const users = useGetUsersSelector()
  const rows: UsersTableRow[] =
    users.data.allIds
      .map(toRow(users))
  return {
    rows,
    loading: users.loading,
    error: users.error,
  }
}

const toRow = (users: GetUsersState) =>
  (id: string, index: number): UsersTableRow => ({
    key: index,
    ...users.data.byId[id]
  })