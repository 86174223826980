import { default as React, FC } from 'react'
import SectionTitle from '../common/SectionTitle'

type SectionProps = {
  title: string
  button?: JSX.Element
  secondary?: boolean
}

const Section: FC<SectionProps> = ({ title, button, secondary, children }) => {
  return (
    <>
      {title && <SectionTitle title={title} button={button} secondary />}
      {children}
    </>
  )
}

export default Section