import { Button, Tooltip } from 'antd'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Id } from '../../../types/common'
import { suspendInfra, useSuspendInfraSelector } from '../slices/suspend'

type Props = {
  id: Id
}

const InfraSuspendButton: FC<Props> = ({ id }) => {
  const dispatch = useDispatch()

  const { loading } = useSuspendInfraSelector(id)

  const handleClick = () => {
    dispatch(suspendInfra(id))
  }

  return (
    <Tooltip
      title={`Suspendre`}
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
        style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
        size='small'
        type='primary'
        loading={loading}
        icon={<i className="ri-pause-line" />}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default InfraSuspendButton