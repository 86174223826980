import { default as React, FC, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Page from '../../../../app/Page'
import AsyncContent from '../../../../common/AsyncContent'
import { getEvents, GetEventsState, useGetEventsSelector } from '../../slices/get'
import EventsBreadcrumb from '../common/Breadcrumb'
import eventsLabels from '../common/labels'
import EventsTable, { EventsTableRow } from './Table'

const EventsPage: FC = () => {
  const dispatch = useDispatch()

  const { rows, ...state } = useEventsTableSelector()

  const fetchData = useCallback(() => {
    dispatch(getEvents())
  }, [dispatch])

  useEffect(fetchData, [fetchData])

  return (
    <Page
      title={eventsLabels.name.plural}
      breadcrumb={EventsBreadcrumb}
    >
      <AsyncContent
        {...state}
        data={rows}
        errorDescription={eventsLabels.get.error}
      >
        <EventsTable rows={rows} />
      </AsyncContent>
    </Page>
  )
}

export default EventsPage

const useEventsTableSelector = () => {
  const events = useGetEventsSelector()
  const rows: EventsTableRow[] =
    events.data.allIds
      .map(toRow(events))
  return {
    rows,
    loading: events.loading,
    error: events.error,
  }
}

const toRow = (events: GetEventsState) =>
  (id: string, index: number): EventsTableRow => ({
    key: index,
    ...events.data.byId[id]
  })