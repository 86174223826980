import React, { FC } from 'react';
import Breadcrumb from '../../../../app/Breadcrumb';
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem';
import useRouting from '../../../../hooks/useRouting';
import customersUsersIcons from './icons';
import customersUsersLabels from './labels';

const UsersBreadcrumb: FC = ({ children }) => {
  const { goToUsers } = useRouting()

  return (
    <Breadcrumb>
      <BreadcrumbItem
        label={customersUsersLabels.name.plural}
        icon={customersUsersIcons.default}
        onClick={() => goToUsers('user-lucie.robin')}
      />
      {children}
    </Breadcrumb>
  )
}

export default UsersBreadcrumb