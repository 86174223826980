import React, { FC } from 'react'
import './Breadcrumb.css'

const Breadcrumb: FC = ({ children }) => {
  return (
    <div className='breadcrumb'>
      {React.Children.toArray(children).map((Child, index) => (
        <span key={index}>
          {index > 0 && <span className='separator'>/</span>}
          {Child}
        </span>
      ))}
    </div>
  )
}

export default Breadcrumb