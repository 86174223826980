import { Button, Result } from 'antd'
import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from '../app/Home'
import ManagerLayout from '../app/Layout'
import ForbiddenPage from '../common/ForbiddenPage'
import NotFoundPage from '../common/NotFoundPage'
import config from '../config/config'
import { AddUserPage, UserDetailsPage, UsersPage } from '../features/customers-users/components'
import { AddCustomerPage, CustomersPage } from '../features/customers/components'
import CustomerDetailsPage from '../features/customers/components/details/Page'
import { EventsPage } from '../features/events/components'
import { AddInfraPage, InfrasTablePage } from '../features/infras/components'
import InfraDetailsPage from '../features/infras/components/details/Page'
import useRouting from '../hooks/useRouting'

const Router: FC = () => {
  const { goToHome } = useRouting()

  const goBackToServices = () => {
    window.location.href = config.coreBaseUrl
    return null
  }

  const goBackToLogin = () => {
    window.location.href = `${config.coreBaseUrl}/logout`
    return null
  }

  return (
    <ManagerLayout>
      <Switch>
        <Route exact path='/logout' component={goBackToLogin} />
        <Route exact path='/services' component={goBackToServices} />
        <Route exact path='/expired' component={Expired} />

        <Route exact path='/' component={Home} />

        <Route exact path='/customers' component={CustomersPage} />
        <Route exact path='/customers/new' component={AddCustomerPage} />
        <Route exact path='/customers/:customerId' component={CustomerDetailsPage} />

        <Route exact path='/customers/:customerId/users' component={UsersPage} />
        <Route exact path='/customers/:customerId/users/new' component={AddUserPage} />
        <Route path='/customers/:customerId/users/:userId' component={UserDetailsPage} />

        <Route exact path='/infras' component={InfrasTablePage} />
        <Route exact path='/infras/new' component={AddInfraPage} />
        <Route exact path='/infras/:infraId' component={InfraDetailsPage} />

        <Route exact path='/events' component={EventsPage} />

        <Route path='/forbidden' component={ForbiddenPage} />

        <Route path='/*'>
          <NotFoundPage onReturn={goToHome} />
        </Route>
      </Switch>
    </ManagerLayout>
  )
}

const Expired: FC = () => {
  const { goBackToLogin: goToCore } = useRouting()

  return <Result
    status="warning"
    title="Votre session a expiré"
    extra={
      <Button type="primary" key="console" onClick={goToCore}>
        Retour au formulaire de login
      </Button>
    }
  />
}

export default Router