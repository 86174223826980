import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Page from '../../../../app/Page'
import AsyncContent from '../../../../common/AsyncContent'
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem'
import { getInfras, useGetInfrasSelector } from '../../slices/get'
import InfrasBreadcrumb from '../common/Breadcrumb'
import infrasIcons from '../common/icons'
import infrasLabels from '../common/labels'
import InfraDetailsForm from './Form'

const InfraDetailsPage: FC = () => {
  const dispatch = useDispatch()
  const { infraId } = useParams() as { infraId: string }

  const { data: { infra }, ...state } = useInfraDetailsPageSelector(infraId)

  useEffect(() => {
    dispatch(getInfras())
  }, [dispatch])

  return (
    <Page
      title={infrasLabels.details.button}
      breadcrumb={Breadcrumb}
    >
      <AsyncContent
        {...state}
        data={infra}
        errorDescription={`Echec du chargement du client`}
      >
        <InfraDetailsForm initialValues={infra} />
      </AsyncContent>
    </Page>
  )
}

const Breadcrumb = () => (
  <InfrasBreadcrumb>
    <BreadcrumbItem
      label={infrasLabels.details.button}
      icon={infrasIcons.details}
    />
  </InfrasBreadcrumb>
)

export default InfraDetailsPage

const useInfraDetailsPageSelector = (id: string) => {
  const infras = useGetInfrasSelector()
  const infra = infras.data.byId[id]
  return {
    data: {
      infra,
    },
    loading: infras.loading,
    error: infras.error,
  }
}