import { combineReducers } from 'redux';
import { authReducer } from '../features/auth';
import { customersUsersReducer } from '../features/customers-users';
import { customersReducer } from '../features/customers';
import watcherReducer from '../features/watcher/watcher';
import { eventsReducer } from '../features/events';
import { infrasReducer } from '../features/infras';

export const rootReducer = combineReducers({
  auth: authReducer,
  watcher: watcherReducer,
  events: eventsReducer,
  customers: customersReducer,
  customersUsers: customersUsersReducer,
  infras: infrasReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer