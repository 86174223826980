import { Space } from 'antd'
import { default as React, FC } from 'react'

type PageLayoutProps = {
  breadcrumb: FC
}

const PageLayout: FC<PageLayoutProps> = ({ breadcrumb: Breadcrumb, children }) => {
  return (
    <Space
      style={{ width: '100%', paddingTop: 16 }}
      size='large'
      direction='vertical'
    >
      {Breadcrumb && <Breadcrumb />}
      <div style={{ padding: 24, background: 'white' }}>
        {children}
      </div>
    </Space>
  )
}

export default PageLayout