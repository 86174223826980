import { UnlockFilled } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Id } from '../../../types/common'
import { lockInfra, useLockInfraSelector } from '../slices/lock'

type Props = {
  id: Id
}

const InfraLockButton: FC<Props> = ({ id }) => {
  const dispatch = useDispatch()

  const { loading } = useLockInfraSelector(id)

  const handleClick = () => {
    dispatch(lockInfra(id))
  }

  return (
    <Tooltip
      title={`Verrouiller`}
      mouseEnterDelay={1}
      mouseLeaveDelay={0}
    >
      <Button
        style={{ fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}
        size='small'
        loading={loading}
        icon={<UnlockFilled />}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default InfraLockButton