import { useSelector } from 'react-redux';
import { makeGetSlice } from 'redux-helper';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ApiError } from '../../../types/api';
import { watchError } from '../../watcher/watcher';
import InfrasApi from '../api';
import { Infra } from '../types';

const {
  reducer: getInfrasReducer,
  actions: { start, success, error, reset },
} = makeGetSlice<Infra>('getInfras')

export type GetInfrasState = ReturnType<typeof getInfrasReducer>

export default getInfrasReducer

export const getInfras = (): AppThunk =>
  async dispatch => {
    try {
      dispatch(start())
      const infras: Infra[] = await InfrasApi.getInfras()
      dispatch(success(infras))
    } catch (err) {
      dispatch(watchError(err as ApiError))
      dispatch(error(err as ApiError))
    }
    dispatch(reset())
  }

export const useGetInfrasSelector = () =>
  useSelector((state: RootState) => state.infras.get)