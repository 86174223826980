import { Rule } from 'rc-field-form/lib/interface'

const siret: Rule = () => ({
  validator(_: any, value: string) {
    if (value.length !== 14) {
      return Promise.reject('Le siret doit être composé de 14 chiffres')
    }
    else if (/[^0-9]/.test(value)) {
      return Promise.reject('Le siret ne peut contenir de caractères non-numériques')
    }
    return Promise.resolve()
  }
})

const machine: Rule = () => ({
  validator(_: any, value: string) {
    if (value.length > 32) {
      return Promise.reject('Le nom de machine ne doit pas dépasser 32 caractères')
    }
    else if (/[^a-z0-9\\-]/.test(value)) {
      return Promise.reject('Le nom de machine ne peut contenir que des minuscules, des chiffres et des tirets')
    }
    return Promise.resolve()
  }
})

const required: Rule = {
  required: true,
  message: 'Ce champ est obligatoire',
}

const email: Rule = {
  type: 'email',
  message: 'Merci de renseigner une adresse email valide',
}

const formRules = {
  siret,
  machine,
  required,
  email,
}

export default formRules