import React, { FC } from 'react'
import Page from '../../../../app/Page'
import BreadcrumbItem from '../../../../common/breadcrumb/BreadcrumbItem'
import UsersBreadcrumb from '../common/Breadcrumb'
import customersUsersIcons from '../common/icons'
import customersUsersLabels from '../common/labels'
import AddUserForm from './Form'

const AddUserPage: FC = () => {
  return (
    <Page title={customersUsersLabels.add.button} breadcrumb={Breadcumb}>
      <AddUserForm />
    </Page>
  )
}

const Breadcumb: FC = () => (
  <UsersBreadcrumb>
    <BreadcrumbItem
      label={customersUsersLabels.add.button}
      icon={customersUsersIcons.add}
    />
  </UsersBreadcrumb>
)

export default AddUserPage