import { Button, Form, Input, message } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useRouting from '../../../../hooks/useRouting'
import formRules from '../../../../utils/rules'
import { addCustomer, useAddCustomerSelector } from '../../slices/add'
import customersLabels from '../common/labels'

const AddCustomerForm: FC = () => {
  const dispatch = useDispatch()
  const { goBack } = useRouting()
  const [name, setName] = useState<string>()
  const [alias, setAlias] = useState<string>()
  const [errorMessage, setErrorMessage] = useState('')

  const { success, error } = useAddCustomerSelector()

  const handleFinish = () => {
    if (name && alias) {
      dispatch(addCustomer({
        name, alias
      }))
    }
  }

  useEffect(() => {
    if (success) {
      goBack()
      message.success(customersLabels.add.success)
    } else if (error) {
      const errorMessageMap: { [code: number]: string } = {
        400: 'Adresse email déjà utilisée',
      }
      const message = errorMessageMap[error.statusCode] || `Erreur interne, merci de réessayer plus tard`
      setErrorMessage(message)
    }
  }, [success, error, goBack, dispatch])

  return (
    <Form
      layout='vertical'
      onFinish={handleFinish}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 11 }}
    >
      <Form.Item
        label='Nom'
        name='name'
        validateTrigger='onBlur'
        rules={[formRules.required]}
      >
        <Input value={name} onChange={e => setName(e.target.value)} />
      </Form.Item>
      <Form.Item
        label='Alias'
        name='alias'
        validateTrigger='onBlur'
        rules={[formRules.required]}
      >
        <Input value={alias} onChange={e => setAlias(e.target.value)} />
      </Form.Item>
      <Form.Item
        style={{ margin: 0 }}
        validateStatus={errorMessage ? 'error' : 'validating'}
        help={errorMessage}
      >
        <Button type='primary' htmlType='submit'>
          Créer
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AddCustomerForm